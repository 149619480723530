
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TopupFeeConfigurationController } from "@/app/ui/controllers/TopupFeeConfigurationController";
import {
  RequestTopupFeeConfigurationList,
  TopupFeeConfiguration,
  TopupFeeConfigurationList,
} from "@/domain/entities/TopupFeeConfiguration";
import { FlagsPermissionTopUpAndCashback } from "@/feature-flags/flags-permission-topup-and-cashback";
import { Vue } from "vue-class-component";

export default class TopUpFee extends Vue {
  goToCreate() {
    this.$router.push("/finance/top-up-and-cashback/top-up-fee/create");
  }
  mounted() {
    this.fetchTopupFeeConfigurationList();
  }
  goToDetail(item: TopupFeeConfiguration) {
    this.$router.push(`/finance/top-up-and-cashback/top-up-fee/${item.topupFeeId}`);
  }
  get getCurrentRoute() {
    return this.$route.meta.name;
  }
  topUpFeeList = new TopupFeeConfigurationList();
  isLoading = false;
  errCause = "";
  async fetchTopupFeeConfigurationList() {
    try {
      this.isLoading = true;
      MainAppController.showLoading();
      this.topUpFeeList = await TopupFeeConfigurationController.getTopupFeeConfigurationList(
        new RequestTopupFeeConfigurationList({
          page: this.topUpFeeList.pagination.page,
          limit: this.topUpFeeList.pagination.limit,
        })
      );
      this.errCause = "";
    } catch (error) {
      this.errCause = parsingErrorResponse(error).type;
    } finally {
      MainAppController.closeLoading();
      this.isLoading = false;
    }
  }
  get columns() {
    const columns = [
      {
        name: "No.",
        styleHead: "w-1/5 text-left",
        render: (_: TopupFeeConfiguration, index: number) => {
          return `<div class="text-black-lp-300">${
            index +
            1 +
            this.topUpFeeList.pagination.limit * (this.topUpFeeList.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Tipe Akun",
        styleHead: "w-2/5 text-left",
        render: (item: TopupFeeConfiguration) => {
          return `<div class="text-black-lp-300">${item.topupFeeAccountType}</div>`;
        },
      },
      {
        name: "Biaya Top Up",
        styleHead: "w-2/5 text-left",
        render: (item: TopupFeeConfiguration) => {
          return `<div class='text-black-lp-300 whitespace-now  flex flex-row'>
                ${formatPrice(item.topupFee)}
                </div>`;
        },
      },
    ];
    return [...columns, ...this.secondColumns];
  }
  get secondColumns() {
    return [
      {
        name: "Status",
        styleHead: "w-2/5 text-left",
        render: (item: TopupFeeConfiguration) => {
          return `<div class="font-normal">${ChipsStyles({
            status: item.topupFeeStatus,
            title:
              item.topupFeeStatus.toLowerCase() === "active" ? "Aktif" : "Tidak Aktif",
          })}</div>`;
        },
      },
      {
        name: "Terakhir Diubah",
        styleHead: "w-3/5 text-left",
        render: (item: TopupFeeConfiguration) => {
          return `<div class="text-black-lp-300">${formatDate(
            item.topupFeeUpdatedAt
          )}</div>
              <div class="capitalize text-gray-lp-500 text-12px">${
                item.topupFeeUpdatedBy
              }</div>`;
        },
      },
    ];
  }
  get isEmpty() {
    return !this.topUpFeeList.data.length && !this.isLoading;
  }
  ableToDetailProcess() {
    return FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable.isEnabled();
  }
  ableToCreate() {
    return FlagsPermissionTopUpAndCashback.permission_finance_top_up_fee_enable.isEnabled();
  }
}
