import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex flex-row justify-end my-4" }
const _hoisted_3 = { class: "relative flex justify-center overflow-hidden h-screen" }
const _hoisted_4 = { class: "overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.ableToCreate)
        ? (_openBlock(), _createBlock(_component_lp_button, {
            key: 0,
            onClick: _ctx.goToCreate,
            title: "Buat Konfigurasi Biaya Top Up",
            customClass: "py-2.5 my-3",
            textColor: "white"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_TableV2, {
          class: "my-2",
          borderTop: false,
          isEmpty: _ctx.isEmpty,
          columns: _ctx.columns,
          data: _ctx.topUpFeeList.data,
          errorCause: _ctx.errCause,
          onTryAgain: _ctx.fetchTopupFeeConfigurationList,
          loading: _ctx.isLoading,
          pagination: _ctx.topUpFeeList.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.topUpFeeList.pagination = $event)),
          onRequest: _ctx.fetchTopupFeeConfigurationList,
          onClick: _ctx.goToDetail,
          isDetailAble: _ctx.ableToDetailProcess,
          paginationStyle: "v3",
          isPinnedScrollBottom: "",
          emptyMessage: "Belum ada data konfigurasi"
        }, null, 8, ["isEmpty", "columns", "data", "errorCause", "onTryAgain", "loading", "pagination", "onRequest", "onClick", "isDetailAble"])
      ])
    ])
  ]))
}